<template>
  <div class="app">
    <!-- <headerList v-if="status"></headerList> -->
    <!-- 详情 -->
    <cephalicBlock :NewList="responseData"></cephalicBlock>
    <div>21111</div>
    <!-- <leftHandList v-if="status"></leftHandList> -->
    <!-- <leftSideBlock v-if="status" :NewList="responseData"></leftSideBlock> -->
  </div>
</template>

<script>
import cephalicBlock from '../../newsModule/cephalicBlock/index.vue';
import headerList from '../../newsModule/headerList/index.vue';
import leftHandList from '../../newsModule/leftHandList/index.vue';
import leftSideBlock from '../../newsModule/leftSideBlock/index.vue';

import { searchArticlePageList } from '@/api/HomePage/Persscenter';

export default {
  components: {
    cephalicBlock,
    headerList,
    leftHandList,
    leftSideBlock,
  },
  data() {
    return {
      responseData: [],
      detailStatus: 0,
      status: false,
      columnId: 0,
    };
  },
  *mounted() {
    this.$watch('$route.query.id', (newId) => {
      this.getList(newId);
    });
  },
  methods: {
    navFn(index) {
      this.countent = index;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    getList() {
      searchArticlePageList(this.columnId).then((request) => {
        console.log('request父', this.columnId);
        this.responseData = request.data.data.data;
      });
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
